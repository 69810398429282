<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="退款详情" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">
					
					<!-- 名称 -->
					<a-form-item label="订单号">
						<a-input disabled v-decorator="[
							'orderid',
							{
							  initialValue: record.data.orderid,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
										

					<!-- 支付金额 -->
					<a-form-item label="支付金额">
						<a-input  disabled v-decorator="[
							'prices',
							{
							  initialValue: record.data.amount,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					
					<!-- 申请退款金额 -->
					<a-form-item label="申请退款金额">
						<a-input  disabled v-decorator="[
							'pricess',
							{
							  initialValue: record.data.amount,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					<a-form-item label="打款方式">
						<a-radio-group v-decorator="[
							'manner',
							{ 
								initialValue:1,
							},
						  ]" button-style="solid">
						  <a-radio-button :value="1">
						    自动打款
						  </a-radio-button>
						  <a-radio-button :value="2">
						    手动打款
						  </a-radio-button>
						</a-radio-group>
						<div style="color: red;font-weight: bold;">
							注：自动打款将直接退还支付金额，手动打款需要客服打款
						</div>
					</a-form-item>
				</a-form>	
			</template>


		</a-modal>
	</div>
</template>

<script>
	export default {

		props: {
			record: Object
		},

		data() {
			return {
				show: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
			}
		},

		created() {

		},
		methods: {

			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
