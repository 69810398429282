var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"okText":"确定","cancelText":"取消","title":"修改尾款"},on:{"ok":_vm.comfirm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[[_c('a-form',{ref:"collectionForm",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"订单号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'orderid',
						{
						  initialValue: _vm.record.data.orderid,
						  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
						},
					  ]),expression:"[\n\t\t\t\t\t\t'orderid',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t  initialValue: record.data.orderid,\n\t\t\t\t\t\t  rules: [{ required: true, message: `请填写名称`, whitespace: true }],\n\t\t\t\t\t\t},\n\t\t\t\t\t  ]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"尾款金额"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'amount',
						{
						  initialValue: _vm.record.data.pending_payment,
						  rules: [
							  { required: true, message: `请填写数字`, },
							  
							  ],
						},
					  ]),expression:"[\n\t\t\t\t\t\t'amount',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t  initialValue: record.data.pending_payment,\n\t\t\t\t\t\t  rules: [\n\t\t\t\t\t\t\t  { required: true, message: `请填写数字`, },\n\t\t\t\t\t\t\t  \n\t\t\t\t\t\t\t  ],\n\t\t\t\t\t\t},\n\t\t\t\t\t  ]"}],attrs:{"min":0,"step":0.01,"type":"text"}})],1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }