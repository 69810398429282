<template>
	<div class="content">
		<!-- 搜索 -->
		<a-card class="acard f-ac">
			<div class="f-ac">
				<div class="f-ac">
					<div class="tips">关键词：</div>
					<a-input-search class="ainputsearch" placeholder="请输入搜索内容" enter-button
						@search="onSearch" />
				</div>
			</div>
		</a-card>
		<!-- 订单列表 -->
		<a-menu v-model="current" mode="horizontal" >
			<template v-for="item in tablist">
				<a-menu-item @click="gettab" :key="item.key">{{item.value}}</a-menu-item>
			</template>
		</a-menu>

		<!-- 表格 -->
		<Table 
		:columns="columns" 
		:datasource="datasource" 
		:pagination="pagination"
		 @gettabledata="gettabledata"
		 @closeorder="closeorder"
		 @changeprice= "changeprice"
		 @sendgoods ="sendgoods"
		 @remarks ="remarks"
		 @resive ="resive"
		 @refund = "refund"
		 @closerefund = "closerefund"
		 >
		</Table>
		<!-- 修改价格 -->
		<Modify ref="modify" :record="record"  @modifynav="modifynav"></Modify>
		
		<!-- 添加备注 -->
		<Remarks ref="remarks" :record="record"  @modifynav="getremarks"></Remarks>
		
		<!-- 修改尾款 -->
		<Resive ref="resive" :record="record"  @modifynav="getresive"></Resive>
		<!-- 退款 -->
		<Refund ref="refund" :record="record"  @modifynav="getrefund"></Refund>
		
		<!-- 驳回退款 -->
		<close-refund ref="closerefund" :record="record"  @modifynav="getcloserefund"></close-refund>
	</div>
</template>

<script>
	import {
		mapState,
		mapGetters,
		mapMutations,
		mapActions
	} from "vuex";
	import Table from "../../components/order/table.vue"
	import Modify from "../../components/order/modify.vue"
	import Remarks from "../../components/order/remarks.vue"
	import Resive from "../../components/order/resive.vue"
	import Refund from "../../components/order/refund.vue"
	import closeRefund from "../../components/order/closerefund.vue"
	export default {
		components: {
			Table,
			Modify,
			Remarks,
			Resive,
			Refund,
			closeRefund
		},
		data() {
			return {
				record:{
					data:"",
					shop:"",
					classify:""
				},
				current: [0],
				tablist:[
					{key:0,value:"订单列表"},
					{key:1,value:"等待买家付款"},
					{key:2,value:"等待发货"},
					{key:3,value:"已发货"},
					{key:4,value:"退款中"},
					{key:5,value:"成功订单"},
					{key:6,value:"关闭订单"},
				],
				pagination: {
					pageSize: 4,
					current: 1,
					totalPage: 0,
					total: 0,
					status: "",
					keyword: "",
					platform: ""
				},
				columns: [

					{
						title: '订单号',
						key: "orderid",
						dataIndex: 'orderid',
						align: 'center',
						width:180,
						fixed: 'left'
					},
					{
						title: '商品名称',
						key: "service_name",
						dataIndex: 'service_name',
						align: 'center',
						width: 100,
						fixed: 'left'
					},
					{
						title: '商品图片',
						key: "show_img",
						dataIndex: 'show_img',
						slots: {
							title: 'show_img'
						}, //插槽列
						scopedSlots: {
							customRender: "show_img"
						}, //插槽列数据
						align: 'center',
						width: 100
					},
					{
						title: '规格名称',
						key: "spec_name",
						dataIndex: 'spec_name',
						slots: {
							title: 'spec_name'
						}, //插槽列
						scopedSlots: {
							customRender: "spec_name"
						}, //插槽列数据
						align: 'center',
						width: 100
					},
					{
						title: '规格价格',
						key: "spec_price",
						dataIndex: 'spec_price',
						slots: {
							title: 'spec_price'
						}, //插槽列
						scopedSlots: {
							customRender: "spec_price"
						}, //插槽列数据
						align: 'center',
						width: 80
					},
					{
						title: '规格数量',
						key: "spec_number",
						dataIndex: 'spec_number',
						slots: {
							title: 'spec_number'
						}, //插槽列
						scopedSlots: {
							customRender: "spec_number"
						}, //插槽列数据
						align: 'center',
						width: 80
					},
					{
						title: '售后',
						key: "sale",
						dataIndex: 'sale',
						slots: {
							title: 'sale'
						}, //插槽列
						scopedSlots: {
							customRender: "sale"
						}, //插槽列数据
						align: 'center',
						width: 120
					},
					{
						title: '买家',
						key: "username",
						dataIndex: 'username',
						align: 'center',
						width: 100
					},
					{
						title: '交易状态',
						key: "btn",
						dataIndex: 'btn',
						slots: {
							title: 'btn'
						}, //插槽列
						scopedSlots: {
							customRender: "btn"
						}, //插槽列数据
						align: 'center',
						width: 150
					},
					{
						title: '实收款',
						key: "amount",
						dataIndex: 'amount',
						slots: {
							title: 'amount'
						}, //插槽列
						scopedSlots: {
							customRender: "amount"
						}, //插槽列数据
						align: 'center',
						width: 150
					},
					{
						title: '尾款信息',
						align: 'center',
						children:[
							{
								title: '待收尾款',
								key: "pending_payment",
								dataIndex: 'pending_payment',
								slots: {
									title: 'pending_payment'
								}, //插槽列
								scopedSlots: {
									customRender: "pending_payment"
								}, //插槽列数据
								align: 'center',
								width: 150
							},
							{
								title: '已付尾款',
								key: "balance_amount",
								dataIndex: 'balance_amount',
								align: 'center',
								width: 150
							}
						]						
					},
					{
						title: '地址',
						key: "address",
						dataIndex: 'address',
						align: 'center',
						width: 150
					},
					{
						title: '终点地址',
						key: "end_address",
						dataIndex: 'end_address',
						align: 'center',
						width: 150
					},
					{
						title: '联系人信息',
						key: "contact_person",
						dataIndex: 'contact_person',
						slots: {
							title: 'contact_person'
						}, //插槽列
						scopedSlots: {
							customRender: "contact_person"
						}, //插槽列数据
						align: 'center',
						width: 150
					},
					{
						title: '下单时间',
						key: "create_time",
						dataIndex: 'create_time',
						align: 'center',
						width: 150
					},
					{
						title: '上门时间',
						key: "service_time",
						dataIndex: 'service_time',
						align: 'center',
						width: 150
					},
					// {
					// 	title: '创建人',
					// 	key: "create_user",
					// 	dataIndex: 'create_user',
					// 	align: 'center'
					// },
					{
						title: '操作',
						key: "action",
						dataIndex: 'action',
						slots: {
							title: 'action'
						}, //插槽列
						scopedSlots: {
							customRender: "action"
						}, //插槽列数据
						align: 'center',
						fixed: 'right',
						width:180
					},
				],
				datasource: []
			}
		},
		created() {
			// 获取表格数据
			this.gettabledata(this.pagination)
		},
		methods: {
			// 表格参数只需要接入不同的方法即可
			...mapActions({
				getorderlist: "orderlist/getorderlist",
				getdel:"orderlist/getdel",
				getedit:"orderlist/getedit",
				getship:"orderlist/getship",
				getaddremarks:"orderlist/getaddremarks",
				getaddresive:"orderlist/getaddresive",
				getaddrefund:"orderlist/getaddrefund"
			}),
			/*
			 *获取数据
			 *pagination 分页参数
			 * 初始化和点击执行
			 */
			async gettabledata(pagination) {
				let data = await this.getorderlist(pagination)
				console.log(data, "点");
				this.pagination.current = pagination.current
				this.pagination.total = data.total
				this.pagination.totalPage = data.totalPage
				this.datasource = data.data

			},
			// 获取不同状态订单
			gettab(e){
				console.log(e);
				this.pagination.status = e.key
				this.gettabledata(this.pagination)
				
			},
			// 订单关闭
			async closeorder(record){
				let payload = {
					orderid :record.orderid
				}
				let msg = await this.getdel(payload)
				this.$message.success(msg)
				//刷新数据
				this.gettabledata(this.pagination)
			},
			// onSearch
			onSearch(e){
				console.log(e,"关键字");
				this.pagination.keyword = e
				this.pagination.current = 1
				this.gettabledata(this.pagination)
			},
			// 订单改价打开弹框
			changeprice(record){
				this.record.data = record
				this.$refs.modify.show = true
			},
			// 修改提交
			async modifynav(payload){
				console.log(payload,"提交参数");
				let msg = await this.getedit(payload)
				this.$message.success(msg)
				// //刷新数据
				await this.gettabledata(this.pagination)
				this.$refs.modify.show = false
			},
			// 发货
			async sendgoods(record){
				let payload = {
					orderid :record.orderid
				}
				let msg = await this.getship(payload)
				this.$message.success(msg)
				//刷新数据
				this.gettabledata(this.pagination)
			},
			// 打开备注框
			remarks(record){
				this.record.data = record
				this.$refs.remarks.show = true
			},
			async getremarks(payload){
				let msg = await this.getaddremarks(payload)
				this.$message.success(msg)
				// //刷新数据
				await this.gettabledata(this.pagination)
				this.$refs.remarks.show = false
			},
			// 打开修改尾款
			resive(record){
				this.record.data = record
				this.$refs.resive.show = true
			},
			async getresive(payload){
				console.log(payload,"提交参数");
				let msg = await this.getaddresive(payload)
				this.$message.success(msg)
				//刷新数据
				await this.gettabledata(this.pagination)
				this.$refs.resive.show = false
			},
			// 打开退款弹框
			refund(record){
				this.record.data = record
				this.$refs.refund.show = true
			},
			async getrefund(payload){
				console.log(payload,"1提交参数");
				payload.status = 3
				payload.review_remarks = ""
				let msg = await this.getaddrefund(payload)
				this.$message.success(msg)
				// //刷新数据
				await this.gettabledata(this.pagination)
				this.$refs.refund.show = false
			},
			//打开驳回退款弹框
			closerefund(record){
				this.record.data = record
				this.$refs.closerefund.show = true
			},
			async getcloserefund(payload){
				console.log(payload,"2提交参数");
				payload.status = 2

				let msg = await this.getaddrefund(payload)
				this.$message.success(msg)
				// //刷新数据
				await this.gettabledata(this.pagination)
				this.$refs.closerefund.show = false
			},
		}
	}
</script>

<style>
</style>
