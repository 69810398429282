<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="修改菜单" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">
					
					<!-- 名称 -->
					<a-form-item label="订单号">
						<a-input disabled v-decorator="[
							'orderid',
							{
							  initialValue: record.data.orderid,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
										

					<!-- 名称 -->
					<a-form-item label="价格">
						<a-input type="number" v-decorator="[
							'price',
							{
							  initialValue: record.data.amount,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
				</a-form>	
			</template>


		</a-modal>
	</div>
</template>

<script>
	export default {

		props: {
			record: Object
		},

		data() {
			return {
				show: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
			}
		},

		created() {

		},
		methods: {

			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
