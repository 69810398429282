<template>
	<div>
		<!-- 
	  bordered :边框线
	  columns：表头
	  slots: { title: '是否显示' }, //插槽列
	  scopedSlots: { customRender: '是否显示' },//插槽列数据
	  @change="handleTableChange" //分页、排序、筛选变化时触发
	  -->
		<a-table 
		bordered 
		:columns="columns" 
		:scroll="{ x: 1300 }"
		:data-source="datasource" 
		:pagination="pagination" 
		row-key="orderid"
		@change="handleTableChange"
		>
			<!-- 图片 -->
			<template  slot="show_img" slot-scope="show_img">
				
				<img class="img-pic" :src="show_img" alt="" />
				
			</template>	
			<!-- 联系人信息 -->
			<template  slot="contact_person" slot-scope="text,record">
				
				<div>{{record.contact_person}}</div>
				<div>{{record.phone}}</div>
			</template>	
			<!-- 已付尾款 -->
			<template  slot="pending_payment" slot-scope="text,record">
				
				<div>{{record.pending_payment}}</div>
				<div>
					<template v-if="Number(record.pending_payment)>0  && Number(record.pending_payment) == Number(record.balance_amount)">
					
					</template>
					<template v-else>
						<a-button  type="primary" @click="resive(record)">
						  修改尾款
						</a-button>
					</template>
				</div>
			</template>	
			<!-- 交易状态 -->
			<template  slot="btn" slot-scope="text,record">
				
				<template v-if="record.btn==1">
					等待买家付款 
					<a-popconfirm
						  okText="确定"
						  cancelText="取消"
						  v-if="datasource.length"
						  title="确认关闭交易?"
						  @confirm="() => closeorder(record)"
						>
						  <a-button type="danger">关闭订单</a-button>
					</a-popconfirm>
				</template>
				<template v-if="record.btn==2">
					<div>已付款 待发货</div>
					
					<a-button  type="primary" @click="sendgoods(record)">
					  发货
					</a-button>
				</template>
				<template v-if="record.btn==3">
					已发货 
				</template>
				<template v-if="record.btn==4">
					已发货 
				</template>
			</template>	
			<!-- 售后 -->
			<template  slot="sale" slot-scope="text,record">
				
				<template v-if="record.sale==1">
					退款中
					<a-popconfirm
						  okText="确定"
						  cancelText="取消"
						  v-if="datasource.length"
						  title="确认驳回退款?"
						  @confirm="() => closerefund(record)"
						>
						  <a-button type="danger">驳回退款</a-button>
					</a-popconfirm>
					<a-button class="mt-15"  type="primary" @click="refund(record)">
					  通过退款
					</a-button>
				</template>
				<template v-if="record.sale==2">
					退款成功
				</template>
				<template v-if="record.sale==3">
					退款失败
				</template>
			</template>	
			
			<!-- 实收款 -->
			<template  slot="amount" slot-scope="text,record">
				
				<template v-if="record.btn==1">
					{{record.amount}}
					<a-button  type="primary" @click="changeprice(record)">
					  修改价格
					</a-button>
				</template>
				<template v-else>
					{{record.amount}}
				</template>
			</template>	
			
			
			
			<template slot="action" slot-scope="text,record">
				<a-button  type="primary" @click="remarks(record)">
					{{"添加备注"}}
				</a-button>
			</template>

		</a-table>
	</div>
</template>
<script>
	export default {
		props: {
			columns: Array,
			datasource: Array,
			pagination: Object,
			
		},
		data() {
			return {
				show:{
					title:"确认禁止",
					txt:"显示"
				},
			};
		},
		mounted() {

		},
		methods: {
			// 分页点击触发
			handleTableChange(pagination, filters, sorter) {
			  console.log(pagination);
			 this.$emit("gettabledata",pagination)
			},

			// 关闭订单提示
			closeorder(record) {
				this.$emit("closeorder", record)
			},
			// changeprice改价
			changeprice(record){
				this.$emit("changeprice", record)
			},
			// 发货
			sendgoods(record){
				this.$emit("sendgoods", record)
			},
			// 备注
			remarks(record){
				this.$emit("remarks", record)
			},
			// 修改尾款
			resive(record){
				this.$emit("resive", record)
			},
			refund(record){
				this.$emit("refund", record)
			},
			closerefund(record){
				this.$emit("closerefund", record)
			}
		},
	};
</script>

<style lang="less" scoped>


	.set {
		flex-direction: column;

		.setitem {
			width: 100%;

			.sort {
				width: 40%;

				.a-input {
					width: 50px;
					padding-left: 10px;
					margin-left: 5px;
				}
			}

			.switch {
				flex: 1;

				.txt {}

				.a-switch {
					margin-left: 5px;
					padding-left: 10px;
				}
			}
		}
	}
</style>
